import React, { useEffect, useState } from 'react';
import './index.css';
import { Typography, IconButton,  Box, Container, Grid, alpha } from '@mui/material';
import { ArrowUpward, ArrowDownward, TrendingUp, Gradient } from '@mui/icons-material';
import {PieChartComponent, PropertyAreaChart, PropertyBarGraph} from '../../../AdminComponents/PropertyGraphComp';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {Avatar, Card, Skeleton } from 'antd'
import { baseUrl, profileImageBaseUrl } from '../../Server/baseUrls';
import { format, addDays } from 'date-fns';
import Birthday_Calendar_Comp from '../../../AdminComponents/Birthday_Calendar_Comp';
import moment from 'moment';
import { updateYearToCurrent } from '../../../Utilities';



const ClientBox = ({ totalValue, value, valueInPerc,valueName, xbgClr }) => {
    return (
      <Box sx={{ border: '1px solid #ccc', borderRadius: '8px', padding: '16px',   /*backgroundImage: 'linear-gradient(to right, #434343 0%, black 100%)', */ backgroundColor: xbgClr }}>
        <Typography variant="h2" color={'#fff'} >{totalValue}</Typography>
        <Typography variant="subtitle2" color={'#fff'}>Total Number of {valueName}</Typography>
        {value ? (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '8px', backgroundColor:'white', borderRadius:'0.5rem', marginTop:'2rem' }}>
            <ArrowUpward sx={{ color: xbgClr }} />
            <Typography variant="body2" sx={{ fontWeight:"bold", color: xbgClr, marginLeft: '4px' }}> {valueInPerc}% Increase since last month</Typography>
            <IconButton aria-label="chart">
              <TrendingUp sx={{ color: xbgClr }} />
            </IconButton>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginTop: '8px', backgroundColor:'white', borderRadius:'0.5rem', marginTop:'2rem' }}>
            <ArrowDownward sx={{ color: xbgClr }} />
            <Typography variant="body2" sx={{ fontWeight:"bold", color: xbgClr, marginLeft: '4px' }}>{valueInPerc}% Decrease since last month</Typography>
            <IconButton aria-label="chart">
              <TrendingUp sx={{ color: xbgClr }} />
            </IconButton>
          </Box>
        )}
      </Box>
    );
}

const propertyDataAsPerArea = [
    { area: 'Dubai Marina', property: 5 },
    { area: 'Downtown', property: 7 },
    { area: 'Dubai Hills', property: 4 },
    { area: 'JBR', property: 3 },
    { area: 'Business Bay', property: 2 },
    { area: 'Palm Jumeirah', property: 7 },
    { area: 'Creek Harbour', property: 7 },
    { area: 'Al Furjan', property: 5 },
    { area: 'Al Jaddaf', property: 1 },
    { area: 'Al Barari', property: 0 },
    { area: 'Al Baraha', property: 2 },
    // Add more areas and property counts as needed
  ];

  const propertyDataAsPerValue = [
    { area: 'Dubai Marina', value: 135000000 },
    { area: 'Downtown', value: 725000000 },
    { area: 'Dubai Hills', value: 925000000 },
    { area: 'JBR', value: 325000000 },
    { area: 'Business Bay', value: 155000000 },
    { area: 'Palm Jumeirah', value: 125000000 },
    { area: 'Creek Harbour', value: 125000000 },
    { area: 'Al Furjan', value: 453000000 },
    { area: 'Al Jaddaf', value: 125000000 },
    { area: 'Al Barari', value: 20900000 },
    { area: 'Al Baraha', value: 25000000 },
    // Add more areas and property amounts as needed
  ];

  const data = [
    { year: 2019, retail: 100, office: 120, industrial: 80 },
    { year: 2020, retail: 110, office: 130, industrial: 85 },
    { year: 2021, retail: 115, office: 135, industrial: 90 },
    // Add more data for other years as needed
  ];

  const propertyDataAsperVolume = [
    { month: 'Jan', properties: 25 },
    { month: 'Feb', properties: 95 },
    { month: 'Mar', properties: 110 },
    { month: 'April', properties: 80 },
    { month: 'May', properties: 67 },
    { month: 'June', properties: 23 },
    { month: 'July', properties: 12 },
    { month: 'August', properties: 10 },
    { month: 'September', properties: 9 },
    { month: 'October', properties: 55 },
    { month: 'November', properties: 23 },
    { month: 'December', properties: 8 },
    // Add more data for other months as needed
  ];

  // Sample data
  const activeClients = 20;
  const inactiveClients = 14;

const AdminDashboardArea = () => {
  const [clients,setClients] = useState("")
  const [properties,setProperties] = useState("")
  const [listed_with_k,setListed_with_kProperties] = useState("")
  const [activeUser,setActiveUser] = useState("")
  const [propertyPerArea,setPropertyPerArea] = useState([])

  const [propertyPerMonth,setPropertyPerMonth] = useState([])
  const [percentageIncreaseClient,setPercentageIncreaseClient] = useState("")
  const [percentageIncreaseProperty,setPercentageIncreaseProperty] = useState("")
  const [percentageDecreaseListedProperty,setPercentageDecreaseListedProperty] = useState("")
  const [loading,setLoading] = useState(true)
  const [sListOfBirthdays,setListOfBirthdays] = useState([]);
  const token = useSelector((state) => state.login.token);
  const axiosConfig = {
      headers: {
          Authorization: `Bearer ${token}`, // Include the token as a Bearer token
      },
  };

  useEffect(() => {
    setLoading(true)
    axios.get(baseUrl+"/api/dashboard",axiosConfig)
    .then((res) =>{
      if(res.data.success){
        setLoading(false)
        setClients(res.data.clients)
        setProperties(res.data.properties)
        setListed_with_kProperties(res.data.properties_listed_with_k)
        setActiveUser(res.data.userCounts)
        setPropertyPerArea(res.data.propertiesPerArea)
        setPropertyPerMonth(res.data.propertiesPerMonth)
        setPercentageIncreaseClient(res.data.percentageIncreaseClient)
        setPercentageIncreaseProperty(res.data.percentageIncreaseProperty)
        setPercentageDecreaseListedProperty(res.data.percentageDecreaseListedProperty)
        // setListOfBirthdays(res.data.birthDays)
        setListOfBirthdays(()=> res.data.birthDays.map(birthday => {
          const updatedDate = updateYearToCurrent(birthday.date);
          return {
              id: birthday.id,
              title: `${birthday.title}, Birthday`,
              start: moment(updatedDate),
              end: moment(updatedDate).add(0, 'days').toDate(),
              profile_image: birthday.profile_image ? `${profileImageBaseUrl}/${birthday.profile_image}` : null,
          };
      }))
      }
    })
      .catch((err) =>{
        setLoading(false)
        console.log("error",err);
      })
  },[]);

  // console.log("************ List of Birthdaysss ***** ", sListOfBirthdays )

    return(
        <Container maxWidth='xl' className='main' >
            <div className='main-first' >
               
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant='h4' >
                        Overview for <b><u> K Estate's Client Portal</u></b>
                    </Typography>
                    <Grid container spacing={{ xs: 2, md: 3 }} /* columns={{ xs: 4, sm: 8, md: 12 }} */ style={{marginTop:"0.5rem"}} >
                        <Grid item xs={12} sm={12} md={4} >
                          {loading?
                          <Skeleton active  paragraph={{ rows: 4, width: ['100%', '100%', '100%', '100%'] }} title={{ width: '100%' }} />:
                            <ClientBox 
                                totalValue={clients} 
                                value={true} 
                                valueInPerc={percentageIncreaseClient} 
                                valueName={'Clients'}
                                xbgClr={' #5398F8'}
                            />
                                }
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} >
                        {loading?<Skeleton active  paragraph={{ rows: 4, width: ['100%', '100%', '100%', '100%'] }} title={{ width: '100%' }}/>:
                            <ClientBox 
                                totalValue={properties} 
                                value={true} 
                                valueInPerc={percentageIncreaseProperty} 
                                valueName={'Property'}
                                xbgClr={'#5856CF'}
                            />
                              }
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} >
                        {loading?<Skeleton active  paragraph={{ rows: 4, width: ['100%', '100%', '100%', '100%'] }} title={{ width: '100%' }} />:
                            <ClientBox 
                                totalValue={listed_with_k} 
                                value={false} 
                                valueInPerc={percentageDecreaseListedProperty} 
                                valueName={'Properties Listed With K-Estates'}
                                // xbgClr={'#E3AF44'}
                                xbgClr={'#CB6560'}
                            />
}
                        </Grid>
                    </Grid>
         
                    <Grid container spacing={{ xs: 2, md: 3 }} /* columns={{ xs: 4, sm: 8, md: 12 }} */ style={{ marginTop: '3rem'  }} display={'flex'} alignItems={'center'} justifyContent={"flex-start"} >
                        <Grid item xs={12} sm={12} md={6} display={'flex'} alignItems={'flex-start'} justifyContent={'flex-start'} flexDirection={'column'} >
                            <Typography variant='h4' >Properties since Last year</Typography>
                            <PropertyAreaChart xDataKey={"month"} data={propertyPerMonth} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} display={'flex'} alignItems={'center'} justifyContent={'center'} flexDirection={'column'} >
                            <Typography variant='h4' >Active / Inactive Clients</Typography>
                            <PieChartComponent activeClients={activeUser.active_count} inactiveClients={activeUser.inactive_count} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ marginTop: '2rem'  }} >
                      <Grid item xs={12} sm={12} md={12} >
                        <h1>Number of Properties As Per Dubai Areas</h1>
                        <PropertyBarGraph data={propertyPerArea} dataKey={'Properties'} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{ marginTop: '2rem'  }} >
                      <Grid item xs={12} sm={12} md={12} >
                        <h1>Birthday Calendar</h1>
                        <Birthday_Calendar_Comp events={sListOfBirthdays} />
                      </Grid>
                    </Grid>
                </Box>
            </div>
        </Container>
    )
}

export default AdminDashboardArea;