import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList
} from 'recharts';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

// Local

import { Box, Typography } from '@mui/material';
import { AmountSaperator } from '../../Utilities';
import moment from 'moment';

// function check 2024
const calculateChanges = (data, period) => {

  if(period == 2 && data.length > period){

    const first_val = data[data.length - 1];
    
    const second_val = data[data.length - (period)];

    const difference = first_val.price - second_val.price;

    const newValue = difference / second_val.price;

    const changesInPercentage = Number(newValue) * 100;

    return changesInPercentage;
  }
  else if(period == 3 && data.length > period ){
    
    const first_val = data[data.length - 1].price;

    const second_val =  data[data.length - 4].price

    const difference = first_val - second_val;

    const newValue = difference / second_val;

    const changesInPercentage = Number(newValue) * 100;

    return changesInPercentage;
  }
  else if(period == 6 && data.length > period ){
    const first_val = data[data.length - 1].price;
    const second_val = data[data.length - 7].price;
    const difference = first_val - second_val;
    const newValue = difference / second_val;
    const changesInPercentage = Number(newValue) * 100;
    return changesInPercentage;
  }
  else if(period == 12 && data.length > period ){

    const first_val = data[data.length - 1].price;

    const second_val =  data[data.length - 12].price;

    const difference = first_val - second_val;

    const newValue = difference / second_val;

    const changesInPercentage = Number(newValue) * 100;
    return changesInPercentage;
  }
}


const CommunityPriceTrends = ({ xCommunityChartData, xCommunityFullData}) => {

    const [lastMonth, setLastMonth] = useState(0);
    const [last3Months, setLast3Months] = useState(0);
    const [last6Months, setLast6Months] = useState(0);
    const [last12Months, setLast12Months] = useState(0);

    useEffect(() => {
        if (xCommunityFullData.length > 1) {
            setLastMonth(calculateChanges(xCommunityFullData, 2));
        }
        if (xCommunityFullData.length > 3) {
            setLast3Months(calculateChanges(xCommunityFullData, 3));
        }
        if (xCommunityFullData.length > 6) {
            setLast6Months(calculateChanges(xCommunityFullData, 6));
        }
        if (xCommunityFullData.length >= 12) {
            setLast12Months(calculateChanges(xCommunityFullData, 12));
        }
    }, [xCommunityFullData]);

    //   const CustomTooltipForPerformingAreas = ({ active, payload, label }) => {
    //     if (active && payload && payload.length) {
    //         return (
    //             <div style={{ backgroundColor:'white', border:'0.5px solid #000', padding:'0.25rem', borderRadius:5 }}>
    //             <Typography variant='subtitle2' >
    //                 {`Month: ${label}`}
    //             </Typography>
    //             <Typography variant='subtitle2' >
    //                 {`Changes: ${payload.change}`}
    //             </Typography>
    //             </div>
    //         );
    //     }
    //     return null;
    //   };

  return (
    <>
        <div style={{ width: '100%', height:500 }}>
            <ResponsiveContainer>
                <BarChart
                    data={xCommunityChartData}
                    margin={{
                        top: 20, right: 30, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip contentStyle={{ fontFamily:"sans-serif" }} labelStyle={{ fontFamily:"sans-serif" }} />
                    <Bar dataKey="change" fill={"black"} >
                        <LabelList dataKey="change" position="top" formatter={(value) => `${value}%`} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>  
        </div>

        <div style={{ width: '100%', height:200 }} >
            <Box display="flex" justifyContent="space-around" mt={4}>
                
                <Box textAlign="center">
                    <Typography variant="subtitle2" style={{ fontFamily:'Montserrat' }} color="textSecondary">Last month</Typography>
                    {
                        lastMonth > 0 ?
                        <ArrowCircleUpIcon style={{ color: "green" }} />
                        :
                        (
                            lastMonth == undefined?
                            <ArrowCircleDownIcon style={{ color: "lightgray" }} />
                            :
                            <ArrowCircleDownIcon style={{ color: "red" }} />
                        )
                    }
                    <Typography 
                    variant="subtitle2" 
                    style={{ fontFamily:'Montserrat' }} 
                    color={
                        lastMonth > 0 && lastMonth < 30 ? "green" 
                        : 
                        ( 
                        lastMonth == undefined ? 
                            "lightgray" 
                            :
                            "red" 
                        )}
                    >
                        Change<br />{
                        lastMonth ? Number(lastMonth).toFixed(2)+"%" : "N/A"}  
                    </Typography>
                </Box>
                
                <Box textAlign="center">
                    <Typography variant="subtitle2" style={{ fontFamily:'Montserrat' }} color="textSecondary">Last 3 months</Typography>
                    {
                        last3Months > 0  ?
                        <ArrowCircleUpIcon style={{ color: "green" }} />
                        :
                        (
                            last3Months == undefined ?
                            <ArrowCircleDownIcon style={{ color: "lightgray" }} />
                            :
                            <ArrowCircleDownIcon style={{ color: "red" }} />
                        )
                    }
                    <Typography 
                        variant="subtitle2" 
                        style={{ fontFamily:'Montserrat' }} 
                        color={
                        last3Months > 0 ? "green" 
                        : 
                        ( 
                            last3Months == undefined ? 
                            "lightgray" 
                            :
                            "red" 
                        )}
                        >
                        Change<br />{
                            last3Months ? Number(last3Months).toFixed(2)+"%" : "N/A"}  
                        </Typography>
                </Box>
                
                <Box textAlign="center">
                    <Typography variant="subtitle2" style={{ fontFamily:'Montserrat' }} color="textSecondary">Last 6 months </Typography>
                    {
                        last6Months > 0 ?
                        <ArrowCircleUpIcon style={{ color: "green" }} />
                        :
                        (
                            last6Months == undefined?
                            <ArrowCircleDownIcon style={{ color: "lightgray" }} />
                            :
                            <ArrowCircleDownIcon style={{ color: "red" }} />
                        )
                    }
                    <Typography 
                        variant="subtitle2" 
                        style={{ fontFamily:'Montserrat' }} 
                        color={
                        last6Months > 0 ? "green" 
                        : 
                        ( 
                            last6Months == undefined ? 
                            "lightgray" 
                            :
                            "red" 
                        )}
                        >
                        Change<br />{
                            last6Months ? Number(last6Months).toFixed(2)+"%" : "N/A"}  
                    </Typography>
                </Box>
                
                {/* <Box textAlign="center">
                    <Typography variant="subtitle2" style={{ fontFamily:'Montserrat' }} color="textSecondary">Last 12 months</Typography>
                    {
                        last12Months > 0 ?
                        <ArrowCircleUpIcon style={{ color: "green" }} />
                        :
                        (
                            last12Months == undefined ? 
                            <ArrowCircleDownIcon style={{ color: "lightgray" }} />
                            :
                            <ArrowCircleDownIcon style={{ color: "red" }} />
                        )
                    }
                        <Typography 
                            variant="subtitle2" 
                            style={{ fontFamily:'Montserrat' }} 
                            color={
                            last12Months > 0 ? "green" 
                            : 
                            ( 
                                last12Months == undefined ? 
                                "lightgray" 
                                :
                                "red" 
                            )}
                            >
                            Change<br />{
                                last12Months ? Number(last12Months).toFixed(2)+"%" : "N/A"}  
                        </Typography>
                </Box> */}

            </Box>
        </div>
        <Typography variant="subtitle2" style={{ fontFamily:'Montserrat', marginTop: "3rem", textAlign:"center", fontSize: '0.65rem' }} color="textSecondary">* The changes are based on Property Monitor data</Typography>

    </>
  );
};

export default CommunityPriceTrends;
